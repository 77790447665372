import * as _ from 'lodash';
import { Injectable, Injector } from '@angular/core'
import { Router } from '@angular/router'
import { BehaviorSubject, Observable, forkJoin, of } from 'rxjs'
import { map } from 'rxjs/operators'
import { switchMap } from 'rxjs/operators'
import { User, UserPublic } from '@models/user.model'
import { Role, BecomeContributorResponse, BecomeContributorParams, PreferencesLinkedBlocks, PreferencesTableColumns, PreferencesDashboards, PreferencesListingFilters } from '@models'
import { CookieService } from 'ngx-cookie-service'
import { Kb, KbRole, Authorization, Society } from '@models'
import { ServiceListings } from '@services/listings.service'
import { TranslateService } from '@ngx-translate/core'
import { Member } from '@models/item.model'
import { JwtHelperService } from '@auth0/angular-jwt'
import { ServiceDatastore } from './datastore.service'
import { CommonService } from './common.service'
import { ServiceSaml } from './saml.service'
import { ServiceKb } from './kbs.service'
import { DuplicateMemberOptions } from '@src/app/models/duplicate-member-options.model'

/**
 * ServiceSecurity
 * @export
 */
@Injectable({ providedIn: 'root' })
export class ServiceSecurity extends CommonService {

  public currentUserObservable: Observable<User>
  private _currentUserSubject: BehaviorSubject<User>

  private _cookieDuration: any
  private _cookieDomain: string
  private _cookieSecured: boolean

  /**
   * Creates an instance of ServiceSecurity.
   * @param {Injector} injector Injector object
   * @param {Router} router Router object
   * @param {ServiceListings} serviceListings Service object
   * @param {CookieService} cookieService Cookie object
   * @param {TranslateService} ngxTranslateService Translate object
   * @param {ServiceDatastore} serviceDatastore Datastore object
   * @param {ServiceSaml} serviceSaml Saml object
   * @param {ServiceKb} serviceKb Kb object
   */
  constructor(
    protected injector: Injector,
    private router: Router,
    private serviceListings: ServiceListings,
    private cookieService: CookieService,
    private ngxTranslateService: TranslateService,
    private serviceDatastore: ServiceDatastore,
    private serviceSaml: ServiceSaml,
    private serviceKb: ServiceKb
  ) {
    super(injector);

    // Only https can have third-party cookies with Chrome (>= 80)
    // So only https can use same cookie for identification :/
    this._cookieSecured = (location.protocol === 'https:') ? true : false;

    const kbDomains = '*.' + this.currentSocietySlug + '.' + this.sld;
    if (this._cookieSecured === false) {
      this._cookieDomain = location.hostname;
    } else {
      this._cookieDomain = kbDomains.split('.').splice(2).join('.') || location.hostname;
    }

    this.setCookieExpiration(0);

    let currentUser = new UserPublic();
    if (!this.cookieService.check('i2kn.token')) {
      localStorage.removeItem('currentUser');
    }
    if (localStorage.getItem('currentUser')) {
      currentUser = new User().deserialize(JSON.parse(localStorage.getItem('currentUser') || ''));
    }

    this._currentUserSubject = new BehaviorSubject<User>(currentUser);
    this.currentUserObservable = this._currentUserSubject.asObservable();
  }

  /**
   * Get the current user
   * @returns {User} The current user
   */
  public get user(): User {
    return this._currentUserSubject.value;
  }

  /**
   * Set the current user
   * @param {User} user The user to set
   */
  public set user(user: User) {
    const userLanguage = user.preferences.language;
    const currentLanguage = this.getCurrentLanguage();
    if (currentLanguage !== userLanguage) {
      this.changeLanguage(currentLanguage);
    }

    this.setLocalStorageUser(user);
    this._currentUserSubject.next(user);
  }

  /**
   * Set the current user from the local storage
   * @param {User} user The user to set
   */
  public setLocalStorageUser(user: User) {
    this.appSettings.settings.dateTimeFormat = _.get(user, 'preferences.dateTimeFormat', this.appSettings.settings.dateTimeFormat);
    this.appSettings.settings.defaultListingView = _.get(user, 'preferences.defaultListingView', this.appSettings.settings.defaultListingView);
    this.appSettings.settings.defaultListingViewOnItem = _.get(user, 'preferences.defaultListingViewOnItem', this.appSettings.settings.defaultListingViewOnItem);
    this.appSettings.settings.emailOnTopic = _.get(user, 'preferences.emailOnTopic', this.appSettings.settings.emailOnTopic);
    this.appSettings.settings.keepFilters = _.get(user, 'preferences.keepFilters', this.appSettings.settings.keepFilters);
    this.appSettings.settings.language = _.get(user, 'preferences.language', this.appSettings.settings.language);
    this.appSettings.settings.oldTitleAsAlias = _.get(user, 'preferences.oldTitleAsAlias', this.appSettings.settings.oldTitleAsAlias);
    this.appSettings.settings.allNotifications = _.get(user, 'preferences.allNotifications', this.appSettings.settings.allNotifications);
    this.appSettings.settings.theme = _.get(user, 'preferences.theme', this.appSettings.settings.theme);
    this.appSettings.settings.summaryFrequency = _.get(user, 'preferences.summaryFrequency', this.appSettings.settings.summaryFrequency);
    this.appSettings.settings.timezone = _.get(user, 'preferences.timezone', this.appSettings.settings.timezone);

    this.appSettings.settings.defaultNbItems = _.get(user, 'preferences.defaultNbItems', this.appSettings.settings.defaultNbItems);
    this.appSettings.settings.defaultNbItemsOnItem = _.get(user, 'preferences.defaultNbItemsOnItem', this.appSettings.settings.defaultNbItemsOnItem);
    this.appSettings.settings.showTags = _.get(user, 'preferences.showTags', this.appSettings.settings.showTags);

    const dashboards = _.extend(
      new PreferencesDashboards(),
      _.get(user, 'preferences.dashboards') as PreferencesDashboards
    );
    this.appSettings.settings.dashboards = dashboards;

    const detailLinkedBlocks = _.extend(
      new PreferencesLinkedBlocks('detail'),
      _.get(user, 'preferences.detailLinkedBlocks') as PreferencesLinkedBlocks
    );
    this.appSettings.settings.detailLinkedBlocks = detailLinkedBlocks;

    const tilesLinkedBlocks = _.extend(
      new PreferencesLinkedBlocks('tiles'),
      _.get(user, 'preferences.tilesLinkedBlocks') as PreferencesLinkedBlocks
    );
    this.appSettings.settings.tilesLinkedBlocks = tilesLinkedBlocks;

    const summariesLinkedBlocks = _.extend(
      new PreferencesLinkedBlocks('summaries'),
      _.get(user, 'preferences.summariesLinkedBlocks') as PreferencesLinkedBlocks
    );
    this.appSettings.settings.summariesLinkedBlocks = summariesLinkedBlocks;

    const tableColumns = _.extend(new PreferencesTableColumns(), _.get(user, 'preferences.tableColumns') as PreferencesTableColumns);
    this.appSettings.settings.tableColumns = tableColumns;

    const filters = _.extend(new PreferencesListingFilters(), _.get(user, 'preferences.filters') as PreferencesListingFilters);
    this.appSettings.settings.filters = filters;

    localStorage.setItem('currentUser', JSON.stringify(user));
  }

  /**
   * This method is used to know if the current user is anonymous.
   * @returns {boolean} True if the current user is anonymous.
   */
  public isCurrentUserAnonymous(): boolean {
    return (!this.user || !this.user.role || Role[this.user.role] === Role.ROLE_PUBLIC);
  }

  /**
   * This method is used to know if the current user is aggrements.
   * @returns {boolean} True if the current user is aggrements.
   */
  public hasAgreements(): boolean {
    return (this.user && !_.isEmpty(this.user.agreements));
  }

  /**
   * This method is used to get the current user's username.
   * @returns {string} The current user's username.
   */
  public getUsername(): string {
    const currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');
    const token = this.getAccessToken();
    const tokenData = (token) ? (new JwtHelperService()).decodeToken(token) : null;

    let username = _.get(currentUser, 'username');
    if (!username || _.trim(username) === '') {
      username = _.get(tokenData, 'username');
    }

    return username;
  }

  /**
   * This method is used to know if the current user is a contributor.
   * @returns {boolean} True if the current user is a reader.
   */
  public isReader(): boolean {
    return this.user.role === Role.ROLE_READER;
  }
  
  /**
   * This method is used to request to become a contributor.
   * @returns {boolean} True if the current user has requested to become a contributor.
   */
  public hasRequestedToBecomeContributor(): boolean {
    return !_.isEmpty(this.user.requestContributor);
  }

  /**
   * This method is used to know if the current user is an admin.
   * @returns {boolean} True if the current user is an admin.
   */
  public isAdmin(): boolean {
    return this.hasMinimumRole(Role.ROLE_KB_ADMIN);
  }

  /**
   * This method is used to know if the current user is a society admin.
   * @returns {boolean} True if the current user is a society admin.
   */
  public isSocietyAdmin(): boolean {
    return this.hasMinimumRole(Role.ROLE_SOCIETY_ADMIN);
  }

  /**
   * This method is used to know if the current user have public permissions
   * @returns {boolean} True if the current user has public permissions
   */
  public isPublic(): boolean {
    return (!this.user.role || Role[this.user.role] === Role.ROLE_PUBLIC);
  }

  /**
   * This method is used to know if the user is the current user.
   * @param {Member} member The member to check
   * @returns {boolean} True if the user is the current user.
   */
  public isCurrentUser(member: Member): boolean {
    return member && (this.user.id === member.id);
  }

  /**
   * This method is used to know if the current user has a minimum role.
   * @param {Role} minimumRole The minimum role to check
   * @returns {boolean} True if the current user has a minimum role.
   */
  public hasMinimumRole(minimumRole: Role): boolean {
    const currentRole = Role[this.user.role];
    if (minimumRole === Role.ROLE_NONE) {
      return true;
    } else if (minimumRole === Role.ROLE_PUBLIC) {
      return (
        currentRole === Role.ROLE_PUBLIC ||
        currentRole === Role.ROLE_READER ||
        currentRole === Role.ROLE_CONTRIBUTOR ||
        currentRole === Role.ROLE_KB_ADMIN ||
        currentRole === Role.ROLE_SOCIETY_ADMIN
      );
    } else if (minimumRole === Role.ROLE_READER) {
      return (
        currentRole === Role.ROLE_READER ||
        currentRole === Role.ROLE_CONTRIBUTOR ||
        currentRole === Role.ROLE_KB_ADMIN ||
        currentRole === Role.ROLE_SOCIETY_ADMIN
      );
    } else if (minimumRole === Role.ROLE_CONTRIBUTOR) {
      return (
        currentRole === Role.ROLE_CONTRIBUTOR ||
        currentRole === Role.ROLE_KB_ADMIN ||
        currentRole === Role.ROLE_SOCIETY_ADMIN
      );
    } else if (minimumRole === Role.ROLE_KB_ADMIN) {
      return (
        currentRole === Role.ROLE_KB_ADMIN ||
        currentRole === Role.ROLE_SOCIETY_ADMIN
      );
    } else if (minimumRole === Role.ROLE_SOCIETY_ADMIN) {
      return (
        currentRole === Role.ROLE_SOCIETY_ADMIN
      );
    }
    return false;
  }

  /**
   * This method is used to get he current user.
   * @returns {Observable<void>} The current user.
   */
  getMe(): Observable<void> {
    return this.http.get<User>(this.urlApi + 'users/me')
      .pipe(
        map(
          (result: any) => {
            this.user = new User().deserialize(result);
          },
          () => {
            this.user = new UserPublic();
          }
        )
      );
  }

  /**
   * This method is used to login.
   * @param {string} username The username
   * @param {string} password The password
   * @returns {Observable<any>} The login result.
   */
  login(username: string, password: string): Observable<any> {
    localStorage.removeItem('authBySaml');
    return this.http.post<any>(this.urlSso + 'authentication/get-token', { _username: username, _password: password })
      .pipe(
        switchMap(res => {
          this.setAccessToken(res.token);
          this.setRefreshToken(res.refresh_token);
          return this.getMe();
        }));
  }

  /**
   * This method is used to logout.
   * @param {boolean} isKbPublic True if the kb is public
   * @param {boolean} redirect Facultative - True if the user must be redirected
   * @returns {void} Nothing.
   */
  logout(isKbPublic: boolean, redirect?: boolean): void {
    this.cookieService.deleteAll('/', this._cookieDomain);
    this.serviceListings.reset();
    this.serviceKb.cleanKbSessionData();
    this.serviceDatastore.reset(isKbPublic);
    localStorage.removeItem('currentUser');
    this.user = new UserPublic();
    this.cookieService.delete('saved-email', '/', this._cookieDomain);
    if (redirect === false) {
      return;
    }
    if (isKbPublic === true) {
      this.router.navigate(['/']);
    } else if (
      (this.serviceSaml.isSamlEnabled() || this.serviceSaml.isSamlTestModeEnabled()) &&
      localStorage.getItem('authBySaml') === 'yes'
    ) {
      localStorage.removeItem('authBySaml');
      window.location.href = this.serviceSaml.getSamlLogoutUrl();
    } else {
      this.router.navigate(['/login']);
    }
  }

  /**
   * This method is used to set a cookie.
   * @param {string} key The cookie key
   * @param {string} value The cookie value
   * @param {number} duration - Facultative - The cookie duration
   * @returns {void} Nothing.
   */
  setCookie(key: string, value: string, duration?: number): void {
    if (!duration && duration !== 0) {
      duration = duration || this._cookieDuration;
    }
    const sameSite = (this._cookieSecured === false) ? 'Strict' : 'Lax';
    this.cookieService.set(key, value, duration, '/', this._cookieDomain, this._cookieSecured, sameSite);
  }

  /**
   * This method is used to get a cookie.
   * @param {string} key The cookie key
   * @returns {string} The cookie value.
   */
  getCookie(key: string): string {
    return this.cookieService.get(key);
  }

  /**
   * This method is used to remove a cookie.
   * @param {string} key The cookie key
   * @returns {void} Nothing.
   */
  removeCookie(key: string): void {
    this.cookieService.delete(key, '/', this._cookieDomain);
  }

  /**
   * This method is used to set the cookie duration.
   * @param {number} duration The cookie duration
   * @returns {void} Nothing.
   */
  setCookieExpiration(duration: number): void {
    this._cookieDuration = duration || 0;
  }

  /**
   * This method is used to change the language.
   * @param {string} newLanguage The new language
   * @returns {void} Nothing.
   */
  changeLanguage(newLanguage: string): void {
    const language = (newLanguage === 'fr' || newLanguage === 'en') ? newLanguage : this.appSettings.settings.language;
    this.setCookie('language', language, 365);
    this.appSettings.settings.language = language;

    this.ngxTranslateService.setDefaultLang(language);
    this.ngxTranslateService.use(language);
  }

  /**
   * This method is used to get the current language.
   * @returns {string} The current language.
   */
  getCurrentLanguage(): string {
    return this.getCookie('language') || this.appSettings.settings.language;
  }

  /**
   * This method is used to get the access token.
   * @returns {string} The access token.
   */
  getAccessToken(): string {
    return this.getCookie('i2kn.token');
  }

  /**
   * This method is used to set the access token.
   * @param {string} token The access token
   * @returns {void} Nothing.
   */
  setAccessToken(token: string): void {
    this.setCookie('i2kn.token', token);
  }

  /**
   * This method is used to get the refresh token.
   * @param {string} refreshToken The refresh token
   * @returns {void} Nothing.
   */
  setRefreshToken(refreshToken: string): void {
    this.setCookie('i2kn.refreshToken', refreshToken);
  }

  /**
   * This method is used to get the refresh access token.
   * @returns {Observable<any>} The refresh access token.
   */
  refreshAccessToken(): Observable<any> {
    const refreshToken = this.cookieService.get('i2kn.refreshToken');
    return this.http.post<any>(this.urlSso + 'api/token/refresh', { refresh_token: refreshToken });
  }

  /**
   * This method is used to become a contributor.
   * @param {BecomeContributorParams} params The params to become a contributor
   * @returns {Observable<BecomeContributorResponse>} The response.
   */
  becomeContributor(params: BecomeContributorParams): Observable<BecomeContributorResponse> {
    return this.http.post<BecomeContributorResponse>(this.urlApi + 'requests/become-contributor', params);
  }

  /**
   * This method is used to get the role by kb.
   * @param {Kb} kb The kb
   * @returns {Role} The role.
   */
  getRoleByKb(kb: Kb): Role {
    const rights = this.user.rights;
    const societySlug = kb.society.slug;
    const kbSlug = kb.slug;

    if (_.get(rights, societySlug + '.admin') === true) {
      return Role.ROLE_SOCIETY_ADMIN;
    }
    return _.get(rights, societySlug + '.' + kbSlug, Role.ROLE_NONE);
  }

  /**
   * This method is used to get if the user has accepted the agreements.
   * @returns {Observable<any>} The response.
   */
  acceptAgreements(): Observable<any> {
    return this.http.post<Object>(this.urlApi + 'agreements', {});
  }

  /**
   * This method is used to save informations about the news popup.
   * @param {string} news The news
   * @param {string} kbSlug The kb slug
   * @returns {void} Nothing.
   */
  saveCloseKbNewsPopup(news: string, kbSlug: string): void {
    news = news || 'undefined-news';
    const key = this.getMd5(this.user.id + kbSlug + '-news');
    const value = this.getMd5(news);
    localStorage.setItem(key, value);
  }

  /**
   * This method is used to know if the news popup is accepted.
   * @param {string} news The news
   * @param {string} kbSlug The kb slug
   * @returns {boolean} True if the news popup is accepted.
   */
  isKbNewsPopupAccepted(news: string, kbSlug: string): boolean {
    news = news || 'undefined-news';
    const key = this.getMd5(this.user.id + kbSlug + '-news');
    const value = this.getMd5(news);
    return localStorage.getItem(key) === value;
  }

  /**
   * This method is used to get the users.
   * @returns {Observable<any>} The response.
   */
  getUsers(): Observable<any> {
    const getMembers = this.http.get(this.urlApi + 'items/members');
    const getUsers = this.http.get(this.urlSso + 'api/kbs/' + this.currentSocietySlug + '/' + this.currentKbSlug);

    return forkJoin([getMembers, getUsers]);
  }

  /**
   * This method is used to change the role.
   * @param {KbRole} kbRole The kb role
   * @param {string} initialRoleKey The initial role key
   * @param {DuplicateMemberOptions} options The options
   * @returns {Observable<any>} The response.
   */
  changeRole(kbRole: KbRole, initialRoleKey: string, options: DuplicateMemberOptions = new DuplicateMemberOptions()): Observable<any> {
    let sentData: { [k: string]: any } = {};
    sentData = {
      'society': kbRole.kb.society.slug,
      'kb': kbRole.kb.slug,
      'role': kbRole.role,
      'roleBefore': initialRoleKey,
      'email': kbRole.member.email,
      'firstname': kbRole.member.firstname,
      'lastname': kbRole.member.lastname
    };
    if (options.duplicateContent === true) {
      const content = _.get(kbRole, 'member.content', '') || '';
      sentData.content = content.replaceAll('"images/', '"' + window.location.origin + '/images/');
    }
    if (options.duplicateJobTitles === true) {
      sentData.jobTitles = _.get(kbRole, 'member.job_titles', '');
    }
    if (options.duplicateAdress === true) {
      sentData.adress = _.get(kbRole, 'member.adress', '');
    }
    if (options.duplicateCompany === true) {
      sentData.company = _.get(kbRole, 'member.company', '');
    }
    if (options.duplicateFacebook === true) {
      sentData.facebook = _.get(kbRole, 'member.facebook', '');
    }
    if (options.duplicateTwitter === true) {
      sentData.twitter = _.get(kbRole, 'member.twitter', '');
    }
    if (options.duplicateLinkedin === true) {
      sentData.linkedin = _.get(kbRole, 'member.linkedin', '');
    }
    if (options.duplicateGoogle === true) {
      sentData.google = _.get(kbRole, 'member.google', '');
    }
    if (options.duplicatePhone === true) {
      sentData.phone = _.get(kbRole, 'member.phone', '');
    }
    if (options.duplicatePicture === true) {
      sentData.pictureUrl = window.location.origin + kbRole.member.getImagePath();
    }

    return this.http.post<any>(kbRole.kb.apiUrl + 'users/change-role', sentData).pipe(
      map(
        () => {
          this.serviceDatastore.updateMemberRole(kbRole.member.email || '', kbRole.role);
        }
      )
    );
  }

  /**
   * This method is used to get all accessible kbs.
   * @param {string} email The email
   * @returns {Observable<any[]>} The response.
   */
  getAllAccessibleKbs(email: string): Observable<any[]> {
    return this.http.get<any>(this.urlApi + 'users/kbs-with-roles').pipe(
      map(
        (result: any) => {
          return _.values(result);
        }
      )
    );
  }

  /**
   * This method is used to get all authorizations.
   * @param {Member} member The member
   * @returns {Observable<Authorization[]>} The response.
   */
  getAllAuthorizations(member: Member): Observable<Authorization[]> {
    const isCreation = _.isUndefined(member.id);
    const isCurrentUser = (!isCreation && this.isCurrentUser(member));

    const encodedEmail = member.email ? member.email.replace(/\+/gi, '%2B') : '';
    const url = (isCreation) ? this.urlApi + 'users/kbs-with-roles' : this.urlApi + 'users/kbs-with-roles?email=' + encodedEmail;//+ member.email;
    const sld = this.sld;

    return this.http.get<any>(url).pipe(
      map(
        (result: any) => {
          const authorizations = [] as Authorization[];
          _.forEach(result, function (societyValue: any, society: any) {
            const kbRoles = [] as KbRole[];

            result[society].sld = sld;

            const memberIsSocietyAdmin = result[society].admin === true;
            _.forEach(_.get(societyValue, 'kbs'), function (kbData: any) {
              const kb = new Kb().deserialize(kbData);
              const currentUserRole = this.getRoleByKb(kb);
              const currentUserIsAdmin = (currentUserRole === Role.ROLE_SOCIETY_ADMIN || currentUserRole === Role.ROLE_KB_ADMIN);
              const isCurrentKb = (kb.slug === this.kbSlug && kb.society.slug === this.societySlug);

              let role: Role;
              if (isCreation && isCurrentKb) {
                role = Role.DEFAUL_CREATION_ROLE;
              } else if (memberIsSocietyAdmin === true) {
                role = Role.ROLE_SOCIETY_ADMIN;
              } else if (isCreation) {
                role = Role.ROLE_NONE;
              } else {
                // No creation so current user
                role = kbData.role || Role.ROLE_NONE;
              }

              if (currentUserIsAdmin || isCurrentUser) {
                kbRoles.push(new KbRole().deserialize({
                  role: role,
                  kb: new Kb().deserialize({
                    slug: kb.slug,
                    title: kb.title,
                    society: new Society().deserialize(result[society])
                  }),
                  member: member
                }));
              }
            }.bind(this));
            if (_.size(kbRoles) > 0) {
              authorizations.push(new Authorization().deserialize({
                society: new Society().deserialize(result[society]),
                kbRoles: kbRoles
              }));
            }
          }.bind(this));
          return authorizations;
        }
      )
    );
  }

  /**
   * This method is used to get the md5.
   * @param {string} str The string
   * @returns {string} The md5.
   */
  getMd5(str: string): string {
    let hash = 0, chr = 0;
    if (str && str.length > 0) {
      for (let i = 0; i < str.length; i++) {
        chr = str.charCodeAt(i);
        hash = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
      }
    }
    return hash.toString();
  }
}
