import * as _ from 'lodash';
import { Component, Injector } from '@angular/core';
import { FilterByComponent } from '../abstract-filter-by.component';
import { first, takeUntil } from 'rxjs/operators';
import { PreferencesListingOrder } from '@src/app/models/preferences-list-order.model';

@Component({
    selector: 'filters-order-by',
    templateUrl: './filters-order-by.component.html',
    styleUrls: ['./filters-order-by.component.scss']
})
export class FiltersOrderByComponent extends FilterByComponent {

    public selectedField = 'lastModificationDate';
    public selectedDirection = 'asc';

    public showScore = false;
    public showLastModificationDate = false;
    public showTitle = true;
    public showPageStatus = false;
    public showCreationDate = false;
    public showNbTopics = false;
    public showNbExperts = false;
    public showGroup = false;
    public showOtherOrderBy = false;
    public translatedField: string;
    public direction: string;

    constructor(
        protected injector: Injector
    ) {
        super(injector);
        this.filterKey = 'orderBy';
    }

    initValue() {
        const params = this.route.snapshot.params;

        this.showScore = (params.type === 'search');

        if (_.has(this.serviceListings.listingOrder, 'field')) {
            this.selectedField = this.serviceListings.listingOrder.field;
        }
        if (_.has(this.serviceListings.listingOrder, 'direction')) {
            this.selectedDirection = this.serviceListings.listingOrder.direction;
        }

        this.serviceListings.tableColumnsObservable.pipe(
            takeUntil(this._destroyed$)
        ).subscribe((tableColumns) => {
            this.showLastModificationDate = (
                _.get(tableColumns, 'Page.modifiers') === true ||
                _.get(tableColumns, 'Worklab.modifiers') === true
            );
            this.showTitle = (
                _.get(tableColumns, 'Page.modifiers') === true ||
                _.get(tableColumns, 'Member.modifiers') === true ||
                _.get(tableColumns, 'Worklab.modifiers') === true
            );
            this.showPageStatus = (
                _.get(tableColumns, 'Page.pageStatus') === true
            );
            this.showCreationDate = (
                _.get(tableColumns, 'Page.creators') === true ||
                _.get(tableColumns, 'Worklab.creators') === true
            );
            this.showNbTopics = (
                _.get(tableColumns, 'Page.nbTopics') === true ||
                _.get(tableColumns, 'Member.nbTopics') === true ||
                _.get(tableColumns, 'Worklab.nbTopics') === true
            );
            this.showNbExperts = (
                _.get(tableColumns, 'Page.experts') === true
            );
            this.showGroup = (
                _.get(tableColumns, 'Page.group') === true ||
                _.get(tableColumns, 'Member.group') === true ||
                _.get(tableColumns, 'Worklab.group') === true
            );
        });

        this.serviceListings.listingOrderObservable.pipe(
            takeUntil(this._destroyed$)
        ).subscribe((listingOrder) => {
            if (listingOrder){
                const field = listingOrder.field;
                const direction = listingOrder.direction;
                const validOrderFields = [
                    'lastModificationDate',
                    'title',
                    'pageStatus',
                    'creationDate',
                    'nbTopics',
                    'nbExperts',
                    'group',
                    'score'
                ];
                if(validOrderFields.includes(field) ) {
                    this.selectedField = listingOrder.field;
                    this.selectedDirection = listingOrder.direction;
                    this.showOtherOrderBy = false;
                } else {
                    delete this.selectedField;
                    delete this.selectedDirection;
                    this.showOtherOrderBy = true;
                    this.translatedField = this.serviceTranslate.instant('ITEM.' + _.upperCase(field) + '.TITLE.TOOLTIP');
                    this.direction = _.lowerCase(this.serviceTranslate.instant('FILTERS.ORDER_BY.' + _.upperCase(direction)));
                }
            }
        });
    }

    reset() {
        const defaultListOrders = new PreferencesListingOrder();
        const listingKey = this.serviceListings.getKey();
        this.appSettings.settings.defaultListOrders = defaultListOrders;
        this.serviceListings.listingOrder = defaultListOrders[listingKey];
        this.appSettings.settings.filters[listingKey] = this.serviceListings.filters;

        if (_.has(this.serviceListings.listingOrder, 'field')) {
            this.selectedField = this.serviceListings.listingOrder.field;
        }
        if (_.has(this.serviceListings.listingOrder, 'direction')) {
            this.selectedDirection = this.serviceListings.listingOrder.direction;
        }
        this.saveOrderBy();
    }

    onChange() {
        this.selectedDirection = this.selectedDirection || 'asc';
        this.serviceListings.listingOrder = {
            field: this.selectedField,
            direction: this.selectedDirection
        };
        this.serviceListings.dataStoreChanged();
        this.saveOrderBy();
    }

    saveOrderBy() {
        const keepFilters = this.servicePreferences.getPreference('keepFilters');
        if (keepFilters === true) {
            const listingKey = this.serviceListings.getKey();
            this.appSettings.settings.defaultListOrders[listingKey] = this.serviceListings.listingOrder;
            this.servicePreferences.savePreference({ 'orderBy': this.appSettings.settings.defaultListOrders }).pipe(
                first(),
                takeUntil(this._destroyed$)
            ).subscribe();
        }
    }
}
