import * as _ from 'lodash';
import { Component, Input, AfterViewInit, OnDestroy, HostListener, ViewChild, ElementRef, NgZone } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { takeUntil, delay, finalize, first } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { ItemsSettings } from '@items/items.settings';
import { Item, MapNode, Member, Page, Worklab, Order } from '@models';
import { ServiceListings, ServiceMap, ServiceUrls, ServiceKb } from '@services';

import { Network, DataSet } from 'vis';


@Component({
  selector: 'items-as-map',
  styleUrls: ['./items-as-map.component.scss'],
  templateUrl: './items-as-map.component.html',
})
export class ItemsAsMapComponent implements AfterViewInit, OnDestroy {

  public datasource: MatTableDataSource<Item>;

  private nodesDataset: DataSet<MapNode>;
  private edgesDataset: DataSet<any>;
  private network: Network;

  private currentSocietySlug: string;
  private currentKbSlug: string;

  private _destroyed$ = new Subject();

  public isLoading = false;
  public loadingProgress = 0;

  public noItem = false;

  @Input() responseEdges?: any;
  @Input() maps?: Item[];
  @Input() options?: any;
  @Input() listOrder?: Order;

  @ViewChild('visjsMap', { static: false }) mapElementRef: ElementRef;

  @HostListener('window:resize')
  onWindowResize(): void {
    this.centerNetwork();
  }

  constructor(
    private ngZone: NgZone,
    private serviceListings: ServiceListings,
    private mapService: ServiceMap,
    private serviceUrls: ServiceUrls,
    private serviceTranslate: TranslateService,
    private router: Router
  ) {
    1
  }

  ngAfterViewInit() {
    this.currentSocietySlug = this.serviceUrls.societySlug;
    this.currentKbSlug = this.serviceUrls.kbSlug;

    if (this.maps) {
      const nodes = [];
      let edges = [];
      _.forEach(this.maps, (item: Item) => {
        nodes.push(this.buildNodeFromItem(item));
      });
      _.forEach(this.responseEdges, (value: any) => {
        const societySlug = this.currentSocietySlug;
        const kbSlug = this.currentKbSlug;
        const edge = {
          from: societySlug + '-' + kbSlug + value.data.source,
          to: societySlug + '-' + kbSlug + value.data.target,
          title: 'Natural link',
          color: {
            color: '#4ED9B8'
          }
        }
        edges.push(edge);
      });
      this.initMap(nodes, edges);
      return;
    }

    /*
    // The idea with this.mapService.getNetwork() was to save the entier state & position
    // of a network into redis through our API So each users dont have to re-compute it.
    // BUT each user can have a different view....
    // It mainly depends on:
    // - filter configuration
    // - foreign kb rights

    let result;
    this.mapService.getNetwork().pipe(
        finalize(
            () => {
                 if(result) {
                    // network retreived from API, just display data
                    this.initMap(result.nodes, result.edges);
                    this.disablePhysics();
                } else { */
    // network not saved in api, build it
    this.serviceListings.datasourceObservable.pipe(
      delay(1),
      takeUntil(this._destroyed$)
    ).subscribe(datasource => {
      if (!datasource) return;
      this.datasource = datasource;

      const hasItems = (_.size(datasource.filteredData) > 0);
      this.noItem = !hasItems;

      if (hasItems) {
        this.isLoading = true;
        this.loadingProgress = 0;

        const nodes = [];
        let edges = [];
        _.forEach(this.datasource.filteredData, (item: Item) => {
          nodes.push(this.buildNodeFromItem(item));
          edges = _.concat(edges, this.buildEdgesFromItem(item));
        });

        edges = _.uniqBy(edges, function (e) {
          return e.from + e.to + e.title;
        });
        this.initMap(nodes, edges);
      }
    });
    /* }
}
),
first(),
takeUntil(this._destroyed$)
).subscribe(
(data: any) => {
if (data && 'nodes' in data && 'edges' in data) {
    result = data;
}
}
);*/
  }


  private disablePhysics() {
    if (this.network) {
      this.network.setOptions({ physics: { enabled: false } });
    }
  }

  private cleanAll() {
    if (this.network) {
      this.network.off('stabilizationProgress', this.onStabilizationProgress);
      this.network.off('doubleClick', this.onNodeDoubleClick);
      this.network.destroy();
      this.network = null;
    }
  }

  private initMap(nodes?: Array<MapNode>, edges?: Array<any>) {
    nodes = nodes || [];
    edges = edges || [];

    this.cleanAll();

    this.nodesDataset = new DataSet(nodes);
    this.edgesDataset = new DataSet(edges);
    this.network = this.ngZone.runOutsideAngular(() =>
      new Network(this.mapElementRef.nativeElement, {
        nodes: this.nodesDataset,
        edges: this.edgesDataset
      }, ItemsSettings.MULTI_ITEMS_MAP)
    );
    this.network.on('stabilizationProgress', this.onStabilizationProgress.bind(this));
    this.network.on('doubleClick', this.onNodeDoubleClick.bind(this));
    this.network.once('stabilizationIterationsDone', this.onStabilizationIterationsDone.bind(this));
  }

  private buildNodeFromItem(item: Item): MapNode {
    const node = this.mapService.getNodeFromItem(item, this.currentSocietySlug, this.currentKbSlug);
    node.id = this.getIdFromItem(item);
    node.fixed = { x: false, y: false };
    if ((item.kb && this.currentKbSlug && item.kb !== this.currentKbSlug) || (item.society && this.currentSocietySlug && item.society !== this.currentSocietySlug)) {
      // Node from another kb, can appear from search
      node.label += ' (' + item.society + '-' + item.kb + ')';
      node.borderWidth = 5;
    }
    return node;
  }

  private getIdFromItem(item: Item): string {
    const societySlug = item.society || this.currentSocietySlug;
    const kbSlug = item.kb || this.currentKbSlug;
    return societySlug + '-' + kbSlug + item.id;
  }

  private buildEdgesFromItem(item: Item): Array<any> {
    const edges = [];
    if (item instanceof Member) {
      _.forEach((item as Member).expertises, (n: any) => {
        n.group = 'Page';
        edges.push({
          from: this.getIdFromItem(n),
          to: this.getIdFromItem(item),
          title: this.serviceTranslate.instant('MAP.EXPERT'),
          color: {
            color: '#F90'
          }
        });
      });

      _.forEach(item.getLinksIn(), (n: any) => {
        if (n instanceof Member) {
          edges.push({
            from: this.getIdFromItem(n),
            to: this.getIdFromItem(item),
            title: this.serviceTranslate.instant('MAP.SUBSCRIBER'),
            color: {
              color: '#70DF8B'
            }
          });
        }
      });

      _.forEach(item.getLinksOut(), (n: any) => {
        if (n instanceof Page) {
          edges.push({
            from: this.getIdFromItem(item),
            to: this.getIdFromItem(n),
            title: this.serviceTranslate.instant('MAP.SUBSCRIBER'),
            color: {
              color: '#70DF8B'
            }
          });
        } else if (n instanceof Member) {
          edges.push({
            from: this.getIdFromItem(item),
            to: this.getIdFromItem(n),
            title: this.serviceTranslate.instant('MAP.SUBSCRIBER'),
            color: {
              color: '#70DF8B'
            }
          });
        } else if (n instanceof Worklab) {
          edges.push({
            from: this.getIdFromItem(item),
            to: this.getIdFromItem(n),
            title: this.serviceTranslate.instant('MAP.WORKLAB_MEMBER'),
            color: {
              color: '#ebed75'
            }
          });
        }
      });
    }

    if (item instanceof Page) {
      _.forEach(item.experts, (n: any) => {
        edges.push({
          from: this.getIdFromItem(item),
          to: this.getIdFromItem(n),
          title: this.serviceTranslate.instant('MAP.EXPERT'),
          color: {
            color: '#F90'
          }
        });
      });

      _.forEach(item.getLinksOut(), (n: any) => {
        if (n instanceof Page) {
          edges.push({
            from: this.getIdFromItem(item),
            to: this.getIdFromItem(n),
            title: this.serviceTranslate.instant('MAP.LINKED_PAGE'),
            color: {
              color: '#da4f70'
            }
          });
        } else if (n instanceof Worklab) {
          edges.push({
            from: this.getIdFromItem(item),
            to: this.getIdFromItem(n),
            title: this.serviceTranslate.instant('MAP.WORKLAB_PAGE'),
            color: {
              color: '#ebed75'
            }
          });
        }
      });

      _.forEach(item.getLinksIn(), (n: any) => {
        if (n instanceof Page) {
          edges.push({
            from: this.getIdFromItem(n),
            to: this.getIdFromItem(item),
            title: this.serviceTranslate.instant('MAP.LINKED_PAGE'),
            color: {
              color: '#da4f70'
            }
          });
        } else if (n instanceof Member) {
          edges.push({
            from: this.getIdFromItem(n),
            to: this.getIdFromItem(item),
            title: this.serviceTranslate.instant('MAP.SUBSCRIBER'),
            color: {
              color: '#70DF8B'
            }
          });
        }
      });
    }

    if (item instanceof Worklab) {
      _.forEach(item.getLinksIn(), (n: any) => {
        if (n instanceof Page) {
          edges.push({
            from: this.getIdFromItem(n),
            to: this.getIdFromItem(item),
            title: this.serviceTranslate.instant('MAP.WORKLAB_PAGE'),
            color: {
              color: '#ebed75'
            }
          });
        } else if (n instanceof Member) {
          edges.push({
            from: this.getIdFromItem(n),
            to: this.getIdFromItem(item),
            title: this.serviceTranslate.instant('MAP.WORKLAB_MEMBER'),
            color: {
              color: '#ebed75'
            }
          });
        }
      });
    }
    return edges;
  }


  private onNodeDoubleClick(p) {
    const nid = _.first<string>(_.get(p, 'nodes'));
    if (nid) {
      const node = this.nodesDataset.get(nid) as any;
      const group = _.toLower(_.get(node, 'group'));
      this.ngZone.run(() => {
        this.router.navigate(['/items/' + group + '/' + ServiceUrls.convertOIDtoID(node.originalId)]);
      })
    }
  }

  private onStabilizationProgress(params) {
    this.ngZone.run(() => {
      this.loadingProgress = Math.round(params.iterations / params.total * 100);
    });
  }

  private onStabilizationIterationsDone() {
    this.ngZone.run(() => {
      setTimeout(() => {
        this.centerNetwork();
      }, 50);
      this.disablePhysics();
      this.isLoading = false;
    });
    /* if(this.serviceSecurity.hasMinimumRole(Role.ROLE_READER)) {
        this.network.storePositions();
        this.mapService.saveNetwork(this.nodesDataset.get(), this.edgesDataset.get()).subscribe();
    } */
  }


  public centerNetwork() {
    if (this.network) {
      this.ngZone.runOutsideAngular(() => {
        this.network.redraw();
        this.network.fit();
      });
    }
  }

  ngOnDestroy() {
    this.cleanAll();
    this._destroyed$.next();
    this._destroyed$.complete();
    this._destroyed$.unsubscribe();
  }
}
