import * as _ from 'lodash';
import { Component, OnDestroy } from '@angular/core'
import { Router } from '@angular/router'
import { Subject } from 'rxjs'
import { first, takeUntil } from 'rxjs/operators'
import { ServiceKb } from '@services/kbs.service'
import { Kb, Society, Role } from '@models'

/**
 * This is the component that displays the search bar.
 * @class SearchBarComponent
 * @implements {OnDestroy}
 */
@Component({
  selector: 'search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnDestroy {
  public q: any
  public isDisplayed = false
  private hasFocus = false
  private timer: any

  public kbs: any[] = []
  public searchType: string = 'normal'

  private readonly _destroyed$ = new Subject()

  /**
   * Creates an instance of SearchBarComponent.
   * @memberof SearchBarComponent
   */
  constructor (
    private readonly router: Router,
    private readonly serviceKb: ServiceKb
  ) {
    this.serviceKb.fetchAllSocieties()
      .pipe(
        first(),
        takeUntil(this._destroyed$)
      )
      .subscribe(
        (societies: Society[]) => {
          const current = _.find(societies, (s: Society) => {
            return s.slug === _.get(this.serviceKb, 'kb.society.slug')
          })
          if (current == null) return
          _.forEach(current.kbs, (kb: Kb) => {
            if (kb.role !== Role.ROLE_NONE) {
              this.kbs.push({
                title: kb.title,
                slug: kb.slug,
                enabled: true
              })
            }
          })
        }
      )
  }

  /**
   * This method is called when the search bar is submitted.
   * @method onSearch
   * @param values - The values of the search bar.
   */
  async search (values: any): Promise<void> {
    const query = _.get(values, 'q')
    const selectedKbs: string[] = []
    _.forEach(this.kbs, (kb: any) => {
      if (kb.enabled === true) {
        selectedKbs.push(kb.slug)
      }
    })
    if (query != null && !_.isEmpty(_.trim(query)) && selectedKbs.length > 0 && this.searchType === 'normal') {
      await this.router.navigate(['/search'], { queryParams: { q: query, kbs: selectedKbs } })
    }
    if (query != null && !_.isEmpty(_.trim(query)) && selectedKbs.length > 0 && this.searchType === 'natural') {
      await this.router.navigate(['/natural'], { queryParams: { q: query, kbs: selectedKbs } })
    }
  }

  /**
   * This method is called when the search bar is focused.
   * @method onFocus
   */
  onFocus (): void {
    this.hasFocus = true
    this.display()
  }

  /**
   * This method is called when the search bar is blurred.
   * @method onBlur
   */
  onBlur (): void {
    this.hasFocus = false
  }

  /**
   * This method is called when the mouse enters the search bar.
   * @method onMouseEnter
   */
  onMouseEnter (): void {
    if (this.hasFocus) {
      this.display()
    }
  }

  /**
   * This method is called when the mouse leaves the search bar.
   * @method onMouseLeave
   */
  onMouseLeave (): void {
    this.hide()
  }

  /**
   * This method is called when the search bar is clicked.
   * @method onClick
   */
  display (): void {
    this.clearTimer()
    this.isDisplayed = true
  }

  /**
   * This method is called when the search bar is clicked.
   * @method onClick
   */
  hide (): void {
    this.clearTimer()
    this.timer = setTimeout(() => {
      this.isDisplayed = false
    }, 2500)
  }

  /**
   * This method is called when the search bar is clicked.
   * @method onClick
   */
  clearTimer (): void {
    if (this.timer != null) {
      clearTimeout(this.timer)
    }
  }

  /**
   * This method is called when a key is pressed.
   * @method onEnter
   * @param event - The event object.
   */
  async onEnter (event: any): Promise<void> {
    if (event.keyCode === 13) {
      await this.search({ q: this.q })
      event.preventDefault()
    }
  }

  /**
   * This method is called when the component is destroyed.
   * @method ngOnDestroy
   */
  ngOnDestroy (): void {
    this.clearTimer()
    this._destroyed$.next()
    this._destroyed$.complete()
    this._destroyed$.unsubscribe()
  }
}
