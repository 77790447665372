import * as _ from 'lodash';
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { finalize, takeUntil, first } from 'rxjs/operators';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { Member, Role, KbRole, Kb } from '@models';
import { ServiceErrors, ServiceKb, ServiceSecurity, ServiceToaster } from '@services';
import { DialogNewMemberConfirmationComponent } from './dialog-new-member-confirmation/dialog-new-member-confirmation.component';
import { DuplicateMemberOptions } from '@src/app/models/duplicate-member-options.model';
import { Subject } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'role',
    templateUrl: './role.component.html',
    styleUrls: ['./role.component.scss']
})
export class RoleComponent implements OnInit, OnDestroy {
    @Output() roleChanged = new EventEmitter();

    @Input() initialRole: string;
    @Input() member: Member;
    @Input() kb?: Kb;

    public loading = false;
    public userCanChangeRole = false;
    public isCreation = false;

    public tooltip: string;
    public role: string;

    private _destroyed$ = new Subject();

    constructor(
        private _dialog: MatDialog,
        private serviceSecurity: ServiceSecurity,
        private serviceToaster: ServiceToaster,
        private serviceErrors: ServiceErrors,
        private serviceKb: ServiceKb
    ) {
    }

    ngOnInit() {
        const kb = this.kb || this.serviceKb.kb;
        const currentUserRole = this.serviceSecurity.getRoleByKb(kb);
        const isCurrentUserAdmin = (currentUserRole === Role.ROLE_SOCIETY_ADMIN || currentUserRole === Role.ROLE_KB_ADMIN);
        const isCurrentKb = this.serviceKb.isCurrentKb(kb);
        const isInitialRoleSocietyAdmin = this.initialRole === Role.ROLE_SOCIETY_ADMIN;

        this.isCreation = _.isUndefined(this.member.id);
        this.userCanChangeRole = !isInitialRoleSocietyAdmin && isCurrentUserAdmin && (!this.isCreation || isCurrentKb);

        if (this.initialRole !== 'ROLE_SOCIETY_ADMIN' && (!this.isCreation || isCurrentKb)) {
            this.tooltip = 'ROLE.CHANGE_ROLE.TOOLTIP';
        } else if (this.initialRole !== 'ROLE_SOCIETY_ADMIN' && this.isCreation && !isCurrentKb) {
            this.tooltip = 'ROLE.CHANGE_ROLE.TOOLTIP_CREATION';
        } else {
            this.tooltip = 'ROLE.CHANGE_ROLE.TOOLTIP_NOT_ROLE_SOCIETY_ADMIN';
        }
        this.role = this.initialRole;
    }

    ngOnDestroy() {
        this._dialog.closeAll();
        this._destroyed$.next();
        this._destroyed$.complete();
        this._destroyed$.unsubscribe();

        this._dialog.closeAll();
    }

    changeRole() {
        const kb = this.kb || this.serviceKb.kb;
        const kbRole = new KbRole().deserialize({
            member: this.member,
            kb: kb,
            role: this.role
        });

        if (this.isCreation) {
            this.roleChanged.emit(kbRole);
        } else {
            if (this.initialRole === Role.ROLE_NONE && false === kb.isCurrentKb) {
                const dialogConfig = new MatDialogConfig();
                dialogConfig.width = '33%';
                dialogConfig.minWidth = '33%';
                const dialogRef = this._dialog.open(DialogNewMemberConfirmationComponent, dialogConfig);
                dialogRef.afterClosed().pipe(
                    first(),
                    takeUntil(this._destroyed$)
                ).subscribe(
                    (result: boolean) => {
                        if (result === true) {
                            this.role = this.initialRole;
                        }
                    }
                );
                dialogRef.componentInstance.optionsChoosen.subscribe((options: DuplicateMemberOptions) => {
                    this._modifyRole(kbRole, options);
                });
            } else {
                this._modifyRole(kbRole);
            }
        }
    }

    private _modifyRole(kbRole: KbRole, options: DuplicateMemberOptions = new DuplicateMemberOptions()) {
        const initialRole = this.initialRole;

        this.loading = true;
        this.serviceSecurity.changeRole(kbRole, initialRole, options).pipe(
            finalize(() => {
                this.loading = false;
            }),
            first(),
            takeUntil(this._destroyed$)
        ).subscribe(
            () => {
                const params = {
                    email: kbRole.member.email,
                    kb: kbRole.kb.title,
                    society: kbRole.kb.society.title
                };
                this.serviceToaster.success('ROLE.CHANGE_ROLE', params);
                this.roleChanged.emit(kbRole);
            },
            (error: HttpErrorResponse) => {
                this.role = this.initialRole;
                this.serviceErrors.handleError('ROLE.CHANGE_ROLE', error);
            }
        );
    }
}
