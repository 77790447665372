import { Component, OnInit, Input } from '@angular/core'
import { HttpErrorResponse } from '@angular/common/http'
import { AppSettings, Settings } from '@app/app.settings'
import { ServiceItems } from '@services';
import { Item } from '@src/app/models'
import { first } from 'rxjs-compat/operator/first';

/**
 * This is the component that displays the items map of the results of a natural search.
 * @class ItemsResponseIaComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-items-ia',
  templateUrl: './items-response-ia.component.html',
  styleUrls: ['./items-response-ia.component.scss']
})
export class ItemsResponseIaComponent implements OnInit {
  public settings: Settings

  @Input() responseItems: any | undefined
  @Input() responseEdges: any | undefined
  @Input() regenerate: () => void
  public error: HttpErrorResponse
  public items: Item[] = []
  public nodes: any
  public edges: any

  public loading = false

  /**
   * Creates an instance of LinksResponseIaComponent.
   * @memberof LinksResponseIaComponent
   */
  constructor(
    private readonly appSettings: AppSettings,
    private serviceItems: ServiceItems,
  ) {
    this.settings = this.appSettings.settings
  }

  /**
   * This method is called when the component is initialized.
   * @method ngOnInit
   */
  ngOnInit(): void {
    this.loading = true
    if (this.responseEdges) {
      this.edges = this.responseEdges
    }
    if (this.responseItems) {
      this.items = []
      this.nodes = this.responseItems.nodes;
      this.responseItems.map((id: string) => {
        this.serviceItems.getItem(id).pipe().subscribe((item: Item) => {
          this.items.push(item)
          if (this.responseItems.length === this.items.length) this.loading = false
        })
      })
    }
  }

  /**
   * This method is called when the response is changed.
   * @method onResponseChange
   * @param event - The event object.
   */
  onResponseChange(): void {
    this.regenerate()
  }
}
