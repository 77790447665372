import { Role } from '@models/roles.enums';
import { Deserializable } from './deserializable.model';
import { PreferencesLinkedBlocks } from './preferences-linked-blocks.model';
import { PreferencesTableColumns } from './preferences-table-columns.model';
import { PreferencesDashboards } from './preferences-dashboards.model';

export class User implements Deserializable {
    id: string;
    username: string;
    title: string;
    group: 'Member';
    filters: any;
    preferences: any;
    rights: any;
    role: Role;

    uid: string;
    url: string;

    mid?: string;
    password?: string;
    firstName?: string;
    lastName?: string;
    token?: string;
    agreements?: string;
    requestContributor?: string;
 
    isAnonymous(): boolean {
        return (!this.role || Role[this.role] === Role.ROLE_PUBLIC);
    }

    deserialize(input: any) {
        if (input.role !== Role.ROLE_PUBLIC) {
            input.uid = (input.id + '').replace('#', '').replace(':', '-');
            input.url = '/items/member/' + input.id.replace('#', '').replace(':', '-');
        }
        input.role = (input.role) ? input.role : Role.ROLE_NONE;

        Object.assign(this, input);
        return this;
    }
}

export class UserPublic extends User {
    constructor() {
        super();

        this.id = '-1';
        this.uid = '-';
        this.filters = [];
        this.mid = '';
        this.title = ' ';
        this.username = ' ';
        this.rights = {};
        this.preferences = {
            dashboards: new PreferencesDashboards(),
            detailLinkedBlocks: new PreferencesLinkedBlocks('detail'),
            tilesLinkedBlocks: new PreferencesLinkedBlocks('tiles'),
            summariesLinkedBlocks: new PreferencesLinkedBlocks('summaries'),
            tableColumns: new PreferencesTableColumns()
        };
        this.role = Role.ROLE_PUBLIC;
    }
}


export interface UserModification {
    id: string;
    email: string;
    title: string;
    role: Role;
}

export interface GetMeResponse {
    requestContributor: string;
}