import * as _ from 'lodash';
import { Injectable, Injector, NgZone } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';

import { TextSelectEvent } from '@shared/directives/text-selection/text-selection.directive';
// tslint:disable-next-line: max-line-length
import { DialogRecommendationsOnTextSelectionComponent } from '@shared/components/froala/dialog-recommendations-on-text-selection/dialog-recommendations-on-text-selection.component';
import { DialogLatexComponent } from '@shared/components/froala/dialog-latex/dialog-latex.component';
import { DialogKekuleComponent } from '@shared/components/froala/dialog-kekule/dialog-kekule.component';
import { first } from 'rxjs/operators';
import { Item, MatDialogKekuleData } from '@models';
import { CommonService } from '@services/common.service';
import { ServiceSecurity } from './security.service';
import { ServiceKekule } from './kekule.service';

declare var $: any;

interface SelectionRectangle {
    left: number;
    top: number;
    width: number;
    height: number;
}

@Injectable({ providedIn: 'root' })
export class ServiceFroala extends CommonService {

    public hostRectangle: SelectionRectangle | null;
    private selectedText: string;
    private editor: any;

    public _item: Item;
    set item(item: Item) {
        this._item = item;
    }

    get item(): Item {
        return this._item;
    }

    constructor(
        protected injector: Injector,
        private serviceSecurity: ServiceSecurity,
        private serviceKekule: ServiceKekule,
        private _dialog: MatDialog,
        private ngZone: NgZone
    ) {
        super(injector);

        this.hostRectangle = null;
        this.selectedText = '';

        $.FroalaEditor.DEFAULTS.language = this.appSettings.settings.language;

        $.FroalaEditor.DEFAULTS.placeholderText = this.serviceTranslate.instant('ITEM.CONTENT.FROALA.TYPE_SOMETHING');

        $.FroalaEditor.DEFAULTS.htmlAllowedAttrs = $.merge($.FroalaEditor.DEFAULTS.htmlAllowedAttrs, [
            'class', 'xmlns', 'version', 'x', 'y', 'stroke', 'stroke-width', 'x1', 'x2', 'y1', 'y2',
            'transform', 'fill', 'font-size', 'font-familly', 'data-molecule-id', 'data-autofit',
            'json-data-chem-obj', /* 'data-mol-2000', 'data-predefined-setting', 'data-widget', */ 'id'
        ]);
        $.FroalaEditor.DEFAULTS.htmlAllowedTags = $.merge($.FroalaEditor.DEFAULTS.htmlAllowedTags, [
            'svg', 'g', 'rect', 'line', 'text'
        ]);
        $.FroalaEditor.DEFAULTS.htmlAllowedEmptyTags = $.merge($.FroalaEditor.DEFAULTS.htmlAllowedEmptyTags, [
            'svg', 'g', 'rect', 'line'
        ]);

        $.FroalaEditor.DEFAULTS.tableStyles = {
            'fr-no-border': this.serviceTranslate.instant('ITEM.CONTENT.FROALA.TABLE_NO_BORDER'),
            'fr-alternate-rows': this.serviceTranslate.instant('ITEM.CONTENT.FROALA.TABLE_ALTERNATE_ROWS'),
            'fr-table-border': this.serviceTranslate.instant('ITEM.CONTENT.FROALA.TABLE_BORDER'),
            'fr-table-centered': this.serviceTranslate.instant('ITEM.CONTENT.FROALA.TABLE_CENTERED')
        };
        $.FroalaEditor.DEFAULTS.tableCellStyles = {
            'fr-header-cell': this.serviceTranslate.instant('ITEM.CONTENT.FROALA.CELL_HEADER'),
            'fr-highlighted': this.serviceTranslate.instant('ITEM.CONTENT.FROALA.CELL_HIGHLIGHTED'),
            'fr-centered-cell': this.serviceTranslate.instant('ITEM.CONTENT.FROALA.CELL_CENTERED'),
            'fr-bordered-cell': this.serviceTranslate.instant('ITEM.CONTENT.FROALA.CELL_BORDER'),
            'fr-red-cell': this.serviceTranslate.instant('ITEM.CONTENT.FROALA.CELL_RED'),
            'fr-smaller-cell': this.serviceTranslate.instant('ITEM.CONTENT.FROALA.CELL_SMALLER')
        };

        $.FroalaEditor.DEFAULTS.linkStyles = {
            'fr-greend': this.serviceTranslate.instant('ITEM.CONTENT.FROALA.GREEN'),
            'fr-strong': this.serviceTranslate.instant('ITEM.CONTENT.FROALA.THICK')
        };

        $.FroalaEditor.DEFAULTS.imageStyles = {
            'fr-rounded': this.serviceTranslate.instant('ITEM.CONTENT.FROALA.ROUNDED'),
            'fr-bordered': this.serviceTranslate.instant('ITEM.CONTENT.FROALA.BORDER'),
            'fr-shadow': this.serviceTranslate.instant('ITEM.CONTENT.FROALA.SHADOW')
        };

        this._defineFroalaButtonCleanAll();
        this._defineFroalaButtonRelatedPages();
        this._defineFroalaButtonKekule();
        this._defineFroalaButtonLatex();
        this._defineFroalaButtonFontAwesome();
    }

    setToolbarTopPosition(): void {
        const postionToolbarElement = $('.fr-toolbar');
        if (postionToolbarElement) {
            const position = postionToolbarElement.position();
            if (position) {
                const topToolbar: number = position.top;
                if (topToolbar < 160) {
                    $('.fr-toolbar').css('top', '160px');
                }
            }
        }
    }

    setToolbarPosition(textareaId: string): void {
        const toolbarContext = (textareaId === '#newTopicTextarea') ? 'topic' : ((textareaId !== '#itemContentTextarea') ? 'subtopic' : 'itemContent');

        let offSetTop = 166; // itemContent
        let maxTop = 160;
        let limitMaxTop = 120;
        let widthOffset = 1;

        if (toolbarContext === 'topic') {
            offSetTop = 246;
            maxTop = 150;
            limitMaxTop = 40;
            widthOffset = 1;
        } else if (toolbarContext === 'subtopic') {
            offSetTop = 166;
            maxTop = 150;
            limitMaxTop = 40;
            widthOffset = 1;
        }

        setTimeout(() => {
            let postionElement = $('.fr-box');
            if (toolbarContext === 'topic') {
                postionElement = $('#topics');
            } else if (toolbarContext === 'subtopic') {
                postionElement = $(textareaId);
            }

            if (postionElement) {
                let position = postionElement.position();
                if (position) {
                    let top: number = position.top + offSetTop;
                    $('.fr-toolbar').css('top', top + 'px');
                    $('.fr-toolbar').css('display', 'block');

                    const width: number = parseInt($(textareaId).css('width'), 10) + widthOffset;
                    $('.fr-toolbar').css('width', width + 'px');
                    $('.mat-tab-body-content').scroll(function (e) {
                        position = postionElement.position();
                        if (position) {
                            top = position.top + offSetTop;
                            if (top > maxTop) {
                                $('.fr-toolbar').css('top', top + 'px');
                                $('.fr-toolbar').show();
                            } else if (top > limitMaxTop) {
                                $('.fr-toolbar').css('top', maxTop + 'px');
                                $('.fr-toolbar').show();
                            } else {
                                $('.fr-toolbar').css('top', '160px');

                                const height = $('.fr-element').height();
                                const visible = top + height;
                                if (visible < 180) {
                                    $('.fr-toolbar').hide();
                                } else {
                                    $('.fr-toolbar').show();
                                }
                            }
                        }
                    });
                }
            }
        }, 500);
    }

    getFroalaConfig(placeholderText: string, item: Item): Object {
        const self = this;
        const language = this.appSettings.settings.language;

        this.item = item;

        const locale_ru_RU = {
            'Crop': 'Découper',
            'Delete-all': 'Tout supprimer'
        };

        return {
            charCounterCount: false,
            language: language,
            iconsTemplate: 'font_awesome_5',
            key: 'lB5A3A4A1uF2C1F1H2A10C1B6A1D6C5hZSZGUSXYSMZb1JGZ==',
            toolbarSticky: false,
            placeholderText: self.serviceTranslate.instant(placeholderText),
            zIndex: 150,
            toolbarVisibleWithoutSelection: true,
            spellcheck: true,
            videoUpload: false,
            pluginsEnabled: [
                'customPlugin', 'align', 'charCounter', 'specialCharacters',
                'codeBeautifier', 'codeView', 'colors', 'draggable', 'fontAwesome',
                'file', 'fontSize', 'fullscreen', 'image', 'imageTUI', 'lineBreaker', 'link',
                'lists', 'paragraphFormat', 'save', 'table', 'url', 'video', 'wordPaste'
            ],
            toolbarButtons: [
                'bold', 'italic', 'underline', 'strikeThrough', 'subscript',
                'superscript', '|', 'fontSize', 'color', '|',
                'paragraphFormat', 'align', 'formatOL', 'formatUL', 'outdent', 'indent', '-',
                'insertLink', 'insertImage', 'insertVideo', 'insertTable', '|',
                'fontAwesome', 'specialCharacters', 'insertHR', 'selectAll', '|',
                'html', '|',
                'undo', 'redo', '|',
                'kekule', '|', 'latex', '|', 'cleanAll', '|', 'relatedPages'
            ],
            imageTUIOptions: {
                includeUI: {
                    initMenu: 'filter',
                    menuBarPosition: 'left',
                    theme: {
                        'menu.activeIcon.path': '/assets/img/tui-image-editor/icon-b.svg',
                        'menu.disabledIcon.path': '/assets/img/tui-image-editor/icon-a.svg',
                        'menu.hoverIcon.path': '/assets/img/tui-image-editor/icon-c.svg',
                        'menu.normalIcon.path': '/assets/img/tui-image-editor/icon-d.svg',
                        'submenu.activeIcon.name': 'icon-c',
                        'submenu.activeIcon.path': '/assets/img/tui-image-editor/icon-c.svg',
                        'submenu.normalIcon.name': 'icon-d',
                        'submenu.normalIcon.path': '/assets/img/tui-image-editor/icon-d.svg'
                    },
                    locale: locale_ru_RU,
                }
            },
            imageUploadURL: this.urlApi + 'files/picture',
            imageUploadParam: 'picture',
            imageUploadMethod: 'POST',
            colorsBackground: [
                '#61BD6D', '#1ABC9C', '#54ACD2', '#2C82C9', '#9365B8', '#475577', '#CCCCCC',
                '#41A85F', '#00A885', '#3D8EB9', '#2969B0', '#553982', '#28324E', '#000000',
                '#F7DA64', '#FBA026', '#EB6B56', '#E25041', '#A38F84', '#E6E6E6', '#FFFFFF',
                '#FAC51C', '#F37934', '#D14841', '#B8312F', '#7C706B', '#D1D5D8', 'REMOVE'
            ],
            events: {
                'froalaEditor.image.error': function (e, editor, error, response) {
                    let translatedMessage = '';
                    let  displayedMessage = '';
                    response = JSON.parse(response);
                    if (response.message === 'Expired JWT Token') {
                        displayedMessage = self.serviceTranslate.instant('ERRORS.IMAGE_TOKEN_EXPIRED');
                        self.serviceToaster.error(displayedMessage);
                    } else {
                        translatedMessage = self.serviceTranslate.instant('ERRORS.' + response.message);
                        displayedMessage = (translatedMessage === 'ERRORS.' + response.message) ? response.message : translatedMessage;
                    }
                    self.serviceToaster.clearAll();
                    self.serviceToaster.error(displayedMessage);
                },
                'froalaEditor.image.beforeUpload': function (e, editor, response) {
                    editor.opts.requestHeaders.Authorization = 'Bearer ' + self.serviceSecurity.getAccessToken();
                },
                'froalaEditor.initialized': function (e, editor) {
                    this.editor = editor;
                    // Check by default "Open in new tab"
                    editor.popups.onShow('link.insert', function () {
                        const $popup = editor.popups.get('link.insert');
                        $popup.find('input[type="checkbox"]').prop('checked', true);
                    });
                    self.serviceKekule.initKekuleViewers(true);
                },
                'froalaEditor.focus': function (e, editor) {
                    self._handleDoubleClickOnLatexPictures();
                    self._handleDoubleClickOnKekuleDiv();
                }
            }
        };
    }

    recommendationsOnSelection(event: TextSelectEvent): void {
        const text = event.text;
        this.recommendationsOnTextSelection(text, this.item);

        // If a new selection has been created, the viewport and host rectangles will
        // exist. Or, if a selection is being removed, the rectangles will be null.
        if (event.hostRectangle) {
            this.hostRectangle = event.hostRectangle;
            this.selectedText = event.text;
        } else {
            this.hostRectangle = null;
            this.selectedText = '';
        }
    }

    recommendationsOnTextSelection(text: string, item: Item): void {
        if (text && _.trim(text).length >= 3) {
            text = _.trim(text.replace(/(<[^><]+>)/g, '').replace(/\s\s+/g, ' '));

            const dialogConfig = new MatDialogConfig();
            dialogConfig.minWidth = '50%';
            dialogConfig.data = { text: text, item: item };
            this.ngZone.run(() => {
                this._dialog.closeAll();
                this._dialog.open(DialogRecommendationsOnTextSelectionComponent, dialogConfig);
            });
        }
    }

    private _defineFroalaButtonCleanAll(): void {
        const self = this;

        $.FroalaEditor.DefineIcon('cleanAll', { NAME: 'eraser', ALT: this.serviceTranslate.instant('ITEM.CONTENT.FROALA.CLEAN_ALL') });
        $.FroalaEditor.RegisterCommand('cleanAll', {
            title: this.serviceTranslate.instant('ITEM.CONTENT.FROALA.CLEAN_ALL'),
            focus: true,
            undo: true,
            refreshAfterCallback: true,
            callback: function () {
                function stripTags(input) {
                    let allowed = '<img><p><div><ul><li><ol><dl><h1><h2><h3><h4><pre><blockquote><svg><g><rect><line><text><hr><table><thead><tr><td><th><tbody>';
                    // making sure the allowed arg is a string containing only tags in lowercase (<a><b><c>)
                    allowed = (((allowed || '') + '')
                        .toLowerCase()
                        .match(/<[a-z][a-z0-9]*>/g) || [])
                        .join('');
                    const tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi,
                        commentsAndPhpTags = /<!--[\s\S]*?-->|<\?(?:php)?[\s\S]*?\?>/gi;
                    return input.replace(commentsAndPhpTags, '').replace(tags, function ($0, $1) {
                        return allowed.indexOf('<' + $1.toLowerCase() + '>') > -1 ? $0 : '';
                    });
                }

                const allText = this.html.get();

                let previousText = this.html.getSelected();
                if (previousText === '<s></s>' || previousText === '') {
                    previousText = this.html.get();
                }

                let thisSelectedText = previousText;
                thisSelectedText = thisSelectedText.replace(/(\r\n|\n|\r)/gm, '');
                thisSelectedText = thisSelectedText.replace(/\u200B/g, '');
                thisSelectedText = thisSelectedText.replace(/&#8203/g, '');

                const newText = stripTags(_.trim(thisSelectedText));
                this.html.set(allText.replace(previousText, newText));

                // $('.latex').removeClass('fr-draggable');
                $('.latex').unbind('click');
                $('.latex').click(function (e) {
                    e.stopPropagation();
                    const alt = this.getAttribute('alt');
                    const src = this.getAttribute('src');
                    const id = this.getAttribute('id');
                    $.FroalaEditor.COMMANDS.latex.callback.call(this, alt, src, id);
                });

                self._handleDoubleClickOnLatexPictures();
            }
        });
    }

    private _defineFroalaButtonRelatedPages(): void {
        const self = this;
        $.FroalaEditor.DefineIcon('relatedPages', { NAME: 'magnet', ALT: this.serviceTranslate.instant('ITEM.CONTENT.FROALA.RELATED_PAGES') });
        $.FroalaEditor.RegisterCommand('relatedPages', {
            title: this.serviceTranslate.instant('ITEM.CONTENT.FROALA.RELATED_PAGES'),
            focus: true,
            undo: true,
            refreshAfterCallback: true,
            callback: function () {
                const selectedText = $('.froala-editor').froalaEditor('selection.text');
                const item = self.item;
                self.recommendationsOnTextSelection(selectedText, item);
            }
        });
    }

    private _defineFroalaButtonKekule(): void {
        const self = this;
        $.FroalaEditor.DefineIcon('kekule', {
            SRC: 'assets/img/molecule.svg',
            ALT: this.serviceTranslate.instant('ITEM.CONTENT.FROALA.KEKULE'),
            template: 'image'
        });
        $.FroalaEditor.RegisterCommand('kekule', {
            title: this.serviceTranslate.instant('ITEM.CONTENT.FROALA.KEKULE'),
            focus: true,
            undo: true,
            refreshAfterCallback: true,
            callback: function () {
                const modalData = { molJson: undefined, element: undefined };
                self.openKekuleModal(modalData);
            }
        });
    }

    public openKekuleModal(modalData: MatDialogKekuleData): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.minWidth = '50%';
        dialogConfig.data = modalData;

        this.ngZone.run(() => {
            this._dialog.closeAll();
            const dialogRef = this._dialog.open(DialogKekuleComponent, dialogConfig);

            dialogRef.componentInstance.moleculeBuilt.pipe(
                first()
            ).subscribe((data: any) => {
                // Insert new kekule element
                if ('outerHTML' in data) {
                    // Delete old kekule element
                    if ('oldElement' in data && data.oldElement) {
                        // Replace element
                        data.oldElement.insertAdjacentHTML('beforeBegin', data.outerHTML);
                        data.oldElement.parentElement.removeChild(data.oldElement);
                        $('.froala-editor').froalaEditor('undo.saveStep');
                    } else {
                        // Insert new element
                        $('.froala-editor').froalaEditor('html.insert', data.outerHTML, false);
                    }
                    setTimeout(() => {
                        this.serviceKekule.initKekuleViewers(true);
                        this._handleDoubleClickOnKekuleDiv();
                    }, 20);
                }
                dialogRef.close();
            });
        });
    }

    private _handleDoubleClickOnKekuleDiv(): void {
        const self = this;
        $('.kekule-mol').unbind('dblclick').dblclick(function (event) {
            const molJson = this.getAttribute('json-data-chem-obj');
            const modalData = { molJson: decodeURI(molJson), element: this };
            self.openKekuleModal(modalData);
            event.stopPropagation();
        });
    }

    public openLatexModal(editor: any, modalData: any, imageId: string) {
        // Remove any previous empty image
        $('img').filter(':not([src]), [src=""]').remove();

        const alt = _.trim(_.get(modalData, 'formula', ''));
        const isNewLatexImage = (alt === '' || alt === 'latex');

        // Insert an empty image in the correct position
        if (isNewLatexImage) {
            imageId = Math.random().toString(36).substring(2, 15);
            editor.html.insert('<img class="latex" src="" alt="" id="' + imageId + '" />');
        }

        const dialogConfig = new MatDialogConfig();
        dialogConfig.minWidth = '50%';
        dialogConfig.data = modalData;

        this.ngZone.run(() => {
            this._dialog.closeAll();
            const dialogRef = this._dialog.open(DialogLatexComponent, dialogConfig);
            dialogRef.componentInstance.imageBuilt.pipe(
                first()
            ).subscribe((imageLatex: any) => {
                $('[id=' + imageId + ']').replaceWith('<img id="' + imageId + '" class="latex" alt="' + imageLatex.formula + '" src="' + imageLatex.url + '" >');
                dialogRef.close();
            });
        });
    }

    private _defineFroalaButtonLatex(): void {
        const self = this;
        $.FroalaEditor.DefineIcon('latex', {
            SRC: 'assets/img/function.png',
            ALT: 'LATEX',
            template: 'image'
        });
        $.FroalaEditor.RegisterCommand('latex', {
            title: this.serviceTranslate.instant('ITEM.CONTENT.FROALA.LATEX'),
            focus: false,
            undo: false,
            refreshAfterCallback: true,
            callback: function (formula: string, imageSrc: string, imageId: string) {
                self.openLatexModal(this, { formula: formula, picture: imageSrc }, imageId);
            }
        });
    }

    private _handleDoubleClickOnLatexPictures(): void {
        $('.latex').unbind('dblclick').dblclick(function (event) {
            const formula = this.getAttribute('alt');
            const imageSrc = this.getAttribute('src');
            const imageId = this.getAttribute('id');
            $.FroalaEditor.COMMANDS.latex.callback.call(this, formula, imageSrc, imageId);
            event.stopPropagation();
        });

        // Remove any previous empty image
        $('img').filter(':not([src]), [src=""]').remove();
    }

    /**
     * Redefining some part of Froala font_awesome plugin:
     * - Set title as 'Icons'
     * - Insert spaces before and after icon so writing will not duplicates icons (bug from default plugin)
     */
    private _defineFroalaButtonFontAwesome(): void {
        $.FroalaEditor.DEFAULTS.fontAwesomeTemplate = '<i class="fa fa-[NAME] fr-deletable" aria-hidden="true"></i>';
        $.FroalaEditor.RegisterCommand('fontAwesome', {
            title: this.serviceTranslate.instant('ITEM.CONTENT.FROALA.FONT_AWESOME_BUTTON'), // i2Kn custom changes here
            icon: 'fontAwesome',
            undo: !1,
            focus: !1,
            modal: !0,
            callback: function() {
                this.fontAwesome.show();
            },
            plugin: 'fontAwesome',
            showOnMobile: !0
        });
        $.FroalaEditor.PLUGINS.fontAwesome = function(n) {
            let i, l, t = 'font_awesome';

            function c(e, o) {
                n.events.disableBlur(), e.focus(), o.preventDefault(), o.stopPropagation()
            }
            return {
                _init: function() {},
                show: function() {
                    if (!i) {
                        const e = '<h4>' + n.language.translate('Font Awesome') + '</h4>';
                        const o = function() {
                                let e1 = '<div class="fr-font-awesome-modal">';
                                for (let o1 = 0; o1 < n.opts.fontAwesomeSets.length; o1++) {
                                    const a1 = n.opts.fontAwesomeSets[o1];
                                    const t1 = a1.list;
                                    let r = '<div class="fr-font-awesome-list"><p class="fr-font-awesome-title">' +
                                            n.language.translate(a1.title) +
                                            '</p>';
                                    for (
                                        let s = 0;
                                        s < t1.length;
                                        s++) {
                                        const i1 = t1[s];
                                        r += '<span class="fr-command fr-font-awesome" tabIndex="-1" role="button" value="' + i1 + '">' + n.opts.fontAwesomeTemplate.replace(/\[NAME\]/g, i1) + '<span class="fr-sr-only">' + n.language.translate('Example of') + i + '&nbsp;&nbsp;&nbsp;</span></span>';
                                    }
                                    e1 += r + '</div>';
                                }
                                return e1 += '</div>';
                        }();
                        const  a = n.modals.create(t, e, o);
                        i = a.$modal, a.$head, l = a.$body, n.events.$on($(n.o_win), 'resize', function() {
                            (i.data('instance') || n).modals.resize(t)
                        }), n.events.bindClick(l, '.fr-font-awesome', function(e) {
                            let o = i.data('instance') || n,
                                a = $(e.currentTarget);
                            o.fontAwesome.insert(a)
                        }), n.events.$on(l, 'keydown', function(e) {
                            var o = e.which,
                                a = l.find('span.fr-font-awesome:focus:first');
                            if (!(a.length || o != $.FE.KEYCODE.F10 || n.keys.ctrlKey(e) || e.shiftKey) && e.altKey) return c(l.find('span.fr-font-awesome:first'), e), !1;
                            if (o == $.FE.KEYCODE.TAB || o == $.FE.KEYCODE.ARROW_LEFT || o == $.FE.KEYCODE.ARROW_RIGHT) {
                                var t = null,
                                    r = null,
                                    s = !1;
                                return o == $.FE.KEYCODE.ARROW_LEFT || o == $.FE.KEYCODE.ARROW_RIGHT ? (r = o == $.FE.KEYCODE.ARROW_RIGHT, s = !0) : r = !e.shiftKey, a.length ? (s && (t = r ? a.nextAll('span.fr-font-awesome:first') : a.prevAll('span.fr-font-awesome:first')), t && t.length || (t = r ? a.parent().next().find('span.fr-font-awesome:first') : a.parent().prev().find('span.fr-font-awesome:' + (s ? 'last' : 'first'))).length || (t = l.find('span.fr-font-awesome:' + (r ? 'first' : 'last')))) : t = l.find('span.fr-font-awesome:' + (r ? 'first' : 'last')), c(t, e), !1
                            }
                            if (o != $.FE.KEYCODE.ENTER || !a.length) return !0;
                            (i.data('instance') || n).fontAwesome.insert(a)
                        }, !0)
                    }
                    n.modals.show(t), n.modals.resize(t)
                },
                hide: function() {
                    n.modals.hide(t)
                },
                insert: function(e) {
                    // i2Kn custom changes here
                    n.fontAwesome.hide(), n.undo.saveStep(), n.html.insert('&nbsp;' + n.opts.fontAwesomeTemplate.replace(/\[NAME\]/g, e.attr('value')) + '&nbsp;', !0), n.undo.saveStep()
                }
            }
        };
    }
}
