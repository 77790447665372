import { Injectable } from '@angular/core';

@Injectable()
export class ItemsSettings {

    public static STORING = {
        'title': {
            'icon': 'alpha',
            'type': 'string'
        },
        'aliases': {
            'icon': 'alpha',
            'type': 'AliasesArray'
        },
        'tags': {
            'icon': 'alpha',
            'type': 'TagsArray'
        },
        'experts': {
            'icon': 'alpha',
            'type': 'MembersArray'
        },
        'creators': {
            'icon': 'alpha',
            'type': 'MembersArray'
        },
        'expertises': {
            'icon': 'alpha',
            'type': 'PagesArray'
        },
        'followers': {
            'icon': 'alpha',
            'type': 'MembersArray'
        },
        'followedPages': {
            'icon': 'alpha',
            'type': 'PagesArray'
        },
        'followedMembers': {
            'icon': 'alpha',
            'type': 'MembersArray'
        },
        'followedWorklabs': {
            'icon': 'alpha',
            'type': 'WorklabsArray'
        },
        'associatedPagesOut': {
            'icon': 'alpha',
            'type': 'PagesArray'
        },
        'participants': {
            'icon': 'alpha',
            'type': 'MembersArray'
        },
        'linkedPages': {
            'icon': 'alpha',
            'type': 'PagesArray'
        },
        'linkedWorklab': {
            'icon': 'alpha',
            'type': 'PagesArray'
        },
        'nbTopics': {
            'icon': 'numeric',
            'type': 'numeric'
        },
        'lastModificationDate': {
            'icon': 'sort',
            'type': 'ItemArray'
        }
    };

    public static ITEM_MAP = {
        nodes: {
            shape: 'dot',
            color: '#666',
            widthConstraint: {
                maximum: 200
            },
            font: {
                size: 14,
                face: 'RobotoDraft, Roboto, "Helvetica Neue", sans-serif'
            },
            size: 8,
            borderWidth: 1
        },
        edges: {
            width: 1,
            shadow: false,
            arrows: 'to',
            color: '#666',
            font: {
                size: 10,
                color: '#999',
                face: 'Arial Narrow'
            },
            widthConstraint: {
                maximum: 200
            },
            smooth: {
                enabled: true,
                type: 'cubicBezier',
                forceDirection: 'horizontal',
                roundness: 0.4
            }
        },
        groups: {
            Page: {
                color: {
                    background: '#da4f70',
                    border: '#C5294E',
                    highlight: {
                        border: '#C5294E',
                        background: '#da4f70'
                    }
                }
            },
            Member: {
                color: {
                    background: '#70DF8B',
                    border: '#25A945',
                    highlight: {
                        border: '#25A945',
                        background: '#70DF8B'
                    }
                }
            },
            Worklab: {
                color: {
                    background: '#ebed75',
                    border: '#EDB374',
                    highlight: {
                        border: '#EDB374',
                        background: '#ebed75'
                    }
                }
            }
        },
        interaction: {
            tooltipDelay: 100,
            hover: true
        },
        layout: {
            improvedLayout: true,
        },
        physics: {
            enabled: true,
            barnesHut: {
                gravitationalConstant: -30000,
                centralGravity: 0.3,
                springLength: 100,
                springConstant: 0.08,
                damping: 0.5,
                avoidOverlap: 1
            },
            maxVelocity: 10,
            solver: 'barnesHut',
        }
    };


    public static MULTI_ITEMS_MAP = {
        nodes: {
            shape: 'dot',
            color: '#666',
            widthConstraint: {
                maximum: 200
            },
            font: {
                size: 14,
                face: 'RobotoDraft, Roboto, "Helvetica Neue", sans-serif'
            },
            size: 8,
            borderWidth: 1,
            shadow: false,
            shapeProperties: {
                interpolation: false    // 'true' for intensive zooming
            }
        },
        edges: {
            width: 1,
            shadow: false,
            arrows: 'to',
            color: '#666',
            font: {
                size: 10,
                color: '#999',
                face: 'Arial Narrow'
            },
            widthConstraint: {
                maximum: 200
            },
            smooth: false
        },
        groups: {
            Page: {
                color: {
                    background: '#da4f70',
                    border: '#C5294E',
                    highlight: {
                        border: '#C5294E',
                        background: '#da4f70'
                    }
                }
            },
            Member: {
                color: {
                    background: '#70DF8B',
                    border: '#25A945',
                    highlight: {
                        border: '#25A945',
                        background: '#70DF8B'
                    }
                }
            },
            Worklab: {
                color: {
                    background: '#ebed75',
                    border: '#EDB374',
                    highlight: {
                        border: '#EDB374',
                        background: '#ebed75'
                    }
                }
            }
        },
        interaction: {
            tooltipDelay: 100,
            hover: true
        },
        layout: {
            improvedLayout:false,
            randomSeed: 9801, // 9x33^2
        },
        physics: {
            stabilization: {
                iterations:500,
                updateInterval:10
            },
            barnesHut: {
                centralGravity: 5.0,
                gravitationalConstant: -50000,
                springLength: 90,
                springConstant: 0.08,
                damping: 0.5,
                avoidOverlap: 1
            }
        }
    };


    public static ITEM_KEYWORDS_MAP = {
        nodes: {
            shape: 'dot',
            color: '#666',
            widthConstraint: {
                maximum: 200
            },
            font: {
                size: 14,
                face: 'RobotoDraft, Roboto, "Helvetica Neue", sans-serif'
            },
            size: 8,
            borderWidth: 1,
            shadow: false,
            shapeProperties: {
                interpolation: false    // 'true' for intensive zooming
            }
        },
        edges: {
            width: 1,
            shadow: false,
            font: {
                size: 10,
                color: '#999',
                face: 'Arial Narrow'
            },
            widthConstraint: {
                maximum: 200
            },
            smooth: false
        },
        groups: {
            Page: {
                color: {
                    background: '#da4f70',
                    border: '#C5294E',
                    highlight: {
                        border: '#C5294E',
                        background: '#da4f70'
                    }
                }
            }
        },
        interaction: {
            tooltipDelay: 100,
            hover: true
        },
        layout: {
            improvedLayout: false,
            randomSeed: 42,
        },
        physics: {
            minVelocity: 1,
            stabilization: {
                iterations: 500,
                updateInterval: 10
            },
            barnesHut: {
                centralGravity: 0.5,
                gravitationalConstant: -30000,
                springLength: 100,
                springConstant: 0.04,
                damping: 0.5,
                avoidOverlap: 0
            }
        }
    };
}
